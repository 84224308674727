/* eslint-disable */
import React, { useState, useEffect  } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import SwiperCore, { Navigation, A11y, Pagination, Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Button from '../../common/Button'
import 'swiper/swiper.scss';
import '../../../../node_modules/swiper/components/effect-fade/effect-fade.scss';

import slide1 from '../../../assets/paperpack/Web_ClusterPacks_HomePage_EN.jpg'
import slide1Fr from '../../../assets/paperpack/Web_ClusterPacks_HomePage_FR.jpg'
import slide2 from '../../../assets/images/facialcubes_home_en.jpg'
import slide2Fr from '../../../assets/images/facialcubes_home_fr.jpg'

import slideFacial from '../../../assets/images/Royale-FacialPI-Website-HomePage_Desktop.png'
import slideFacialMobile from '../../../assets/images/Royale-FacialPI-Website-HomePage_Mobile.png'

import slide1Mobile from '../../../assets/paperpack/Web_ClusterPacks_HomePage_EN.jpg'
import slide1MobileFr from '../../../assets/paperpack/Web_ClusterPacks_HomePage_FR.jpg'
import slide2Mobile from '../../../assets/images/facialcubes_home_en_mobile.jpg'
import slide2MobileFr from '../../../assets/images/facialcubes_home_fr_mobile.jpg'



import paperbg from '../../../assets/images/promotions/paperpack/home-paperbg.png';
import mobilepaperbg from '../../../assets/images/promotions/paperpack/home-mobile-paperbg.jpg';
import IsMobile from "../../common/IsMobile";
import IsDesktop from "../../common/IsDesktop";

SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectFade]);

const MainContainer = styled.div`
  position: relative;

  .swiper,
  .swiper-container {
    height: 100%;
  }

  .swiper-button-prev,
  .swiper-button-next {
    cursor: pointer;
    position: absolute;
    z-index: 20;
    width: 40px;
    text-align: center;
    background: #5375B4;
    border-radius: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swiper-container-fade .swiper-slide-active {
    z-index: 2;
  }

  .swiper-button-prev {
    top: calc(50% - 25px);
    left: 10px;

    @media screen and (max-width: 40em) {
      top: 220px;
    }
  }

  .swiper-button-next {
    top: calc(50% - 20px);
    right: 10px;
    transform: rotate(180deg);

    @media screen and (max-width: 40em) {
      top: 220px;
    }
  }

  .swiper-button-prev:before,
  .swiper-button-next:before {
    content:"‹";
    font-size: 60px;
    line-height: 1;
    font-weight: bold;
    color: #fff;
    position: relative;
    top: -4px;
    left: -2px;
    ${tw`font-montserrat`}
  }

  .swiper-button-prev:before {
    top: -5px;
    left: -3px;
  }

  .swiper-button-disabled {
    display: none;
  }
`

const SlideContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 64em) {
    display: flex;
    flex-direction: row-reverse;
  }
`

const Left = styled.div`
  flex-basis: 30%;
  padding: 2rem 2rem;

  @media screen and (min-width: 64em) {
    padding-left: 80px;
    padding-right: 50px;
  }

  @media screen and (max-width: 40em) {
    flex-basis: auto;
  }
`

const Right = styled.div`
  flex-basis: 70%;
  position: relative;

  img {
    width: 100%;
    height: auto;
    max-width: 100%;
  }

  @media screen and (max-width: 40em) {
    height: auto;
    flex-basis: auto;
  }
`

const Header = styled.h2`
  ${tw`font-raleway text-4xl font-light text-black leading-tight m-0 mb-6`}
  width: 100%;
`

const Header2 = styled.h3`
  ${tw`font-raleway text-2xl font-medium text-black leading-tight m-0 mb-6`}
  width: 100%;
`

const Body = styled.p`
  ${tw`font-raleway font-normal text-black leading-loose m-0 mb-6`}
  width: 100%;
`

const TextWrapper = styled.div`
  max-width: 400px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
`

const RightText = styled.div`
  position: absolute;
  left: 40px;
  bottom: 40px;

  ${tw`font-raleway font-normal text-white leading-tight m-0`}

  strong {
    font-weight: 500;
    font-size: 18px;
  }
`

const Logo = styled.img`
  position: absolute;
  right: 25px;
  top: 25px;
  z-index: 2;
  width: 120px;

  @media screen and (min-width: 40em) {
    width: 150px;
    right: 40px;
    top: 40px;
  }
`

const PaperButton = styled(Button)`
  background: #5375B4;
  color: #fff;
  white-space: normal !important;
  font-size: .75rem;
  border: 1px solid #5375B4;
  &:hover,
  &:focus {
    background: #fff;
    color: #5375B4;
    box-shadow: 0 0 0 3px #738dbd;
    border-color: #5375B4;
  }
`

const PaperContainer = styled.div`
  position: relative;
  z-index: 1;
`

const HomeHeadText = styled.h1`
  margin: 3rem 0 -4rem 0;
  text-align: center;
  font-family: "Raleway", "Lucida Grande", Tahoma, sans-serif;
  font-weight: 200;
  font-size: 2.5rem;
  p {
    margin: 0;
  }
  @media screen and (max-width: 1366px) {
    margin: 4rem 0 0;
  }
`

const PaperBox = styled.div`
  ${tw`flex items-center`}
  @media screen and (max-width: 1024px) {
    ${tw`flex-col-reverse`}
  }
`

const PaperLeft = styled.div`
  width: 50%;
  max-width: 620px;
  margin-left: auto;
  padding-right: 250px;
  ${tw`font-raleway`}
  h3 {
    margin: 0 0 .5rem;
    color: #00783F;
    font-size: 1.5rem;
    font-weight: normal;
  }
  h4 {
    margin: 0.5rem 0 0;
    color: #00783F;
    font-size: 1.5rem;
    font-weight: normal;
  }
  p {
    line-height: 2;
    color: #555555;
    font-size: 1rem;
  }
  :lang(fr) {
    h3 {
      margin: .5rem 0 0;
    }
  }
  @media screen and (max-width: 1250px) {
    padding-left: 1rem;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: none;
    padding: 0 1rem;
  }
`

const PaperImage = styled.img`
  position: relative;
  z-index: 1;
`

const PaperRight = styled.div`
  width: 50%;
  background: url(${paperbg}) no-repeat;
  background-size: cover;
  height: 665px;
  position: relative;
  ${PaperImage} {
    margin-left: -150px;
    margin-top: 87px;
    width: 600px;
  }
  @media screen and (max-width: 1300px) {
    ${PaperImage} {
      margin-top: 50px;
    }
    overflow-x: hidden;
  }
  @media screen and (max-width: 1024px) {
    background: url(${mobilepaperbg}) no-repeat;
    background-size: 100% 100%;
    height: 360px;
    width: 100%;
    margin-bottom: 1rem;
    ${PaperImage} {
      margin: 0 auto;
      width: 420px;
    }
  }
  @media screen and (max-width: 60em) {
    background-size: contain;
    height: 345px;
  }
  @media screen and (max-width: 500px) {
    background-size: 100% 70%;
    ${PaperImage} {
      margin-right: -40px;
    }
  }
`

const PaperButtons = styled.div`
  display: flex;
  ${PaperButton} {
    margin: 0 10px 0 0;
  }
  @media screen and (max-width: 500px) {
    display: block;
    ${PaperButton} {
      margin: 10px 0;
      width: 100%;
    }
  }
`

const PaperNewImage = styled.img`
  position: absolute;
  top: 127px;
  left: 265px;
  @media screen and (max-width: 1450px) {
    width: 300px;
    left: 280px;
  }
  @media screen and (max-width: 1250px) {
    left: 220px;
  }
  @media screen and (max-width: 1100px) {
    left: 180px;
  }
  @media screen and (max-width: 1024px) {
    left: -10px;
    top: 0;
    width: 100px;
    z-index: 2;
  }
`

const HomeHeader = ({locale}) => {
  return (
    <MainContainer>
      <Swiper
        navigation
        a11y
        effect="fade"
        fadeEffect={{ crossFade: true }}
        autoHeight
        autoplay={{delay:5000}}
      >
        <SwiperSlide>
          <SlideContainer>
            <Right>
              <IsDesktop>
                <img src={locale == 'fr' ? slideFacial:slideFacial } alt="Royale" />
              </IsDesktop>
              <IsMobile>
                <img src={locale == 'fr' ? slideFacialMobile:slideFacialMobile } alt="Royale" />
              </IsMobile>
            </Right>
            <Left>
              <TextWrapper>
                <Header>
                  <FormattedHTMLMessage id="facial.hero1" />
                </Header>
                <Body>
                  <FormattedHTMLMessage id="facial.hero2" />
                </Body>
                { locale === 'fr' ?
                  <PaperButton to="/fr/produits/mouchoirs/" modification="pets" convert={false}>
                    Plus d’info
                  </PaperButton>
                  :
                  <PaperButton to="/products/facial-tissue/" modification="pets" convert={false}>
                    Learn more
                  </PaperButton>
                }
              </TextWrapper>
            </Left>
          </SlideContainer>
        </SwiperSlide>
        <SwiperSlide>
          <SlideContainer>
            <Right>
              <IsDesktop>
                <img src={locale == 'fr' ? slide1Fr:slide1 } alt="Royale" />
              </IsDesktop>
              <IsMobile>
                <img src={locale == 'fr' ? slide1MobileFr:slide1Mobile } alt="Royale" />
              </IsMobile>
            </Right>
            <Left>
              <TextWrapper>
                <Header>
                  <FormattedHTMLMessage id="carbon.hero1" />
                </Header>
                <Body>
                  <FormattedHTMLMessage id="carbon.hero2" />
                </Body>
                { locale === 'fr' ?
                  <PaperButton to="/fr/carboneutre" aria-label="Plus d’info" title="Plus d’info" modification="pets" convert={false}>
                    Plus d’info
                  </PaperButton>
                  :
                  <PaperButton to="/carbon-neutral" aria-label="Learn more" title="Learn more" modification="pets" convert={false}>
                    Learn more
                  </PaperButton>
                }
              </TextWrapper>
            </Left>
          </SlideContainer>
        </SwiperSlide>
        <SwiperSlide>
          <SlideContainer>
            <Right>
              <IsDesktop>
                <img src={locale == 'fr' ? slide2Fr:slide2 } alt="Royale" />
              </IsDesktop>
              <IsMobile>
                <img src={locale == 'fr' ? slide2MobileFr:slide2Mobile } alt="Royale" />
              </IsMobile>
            </Right>
            <Left>
              <TextWrapper>
                <Header>
                  <FormattedHTMLMessage id="paperpack.text1" />
                </Header>
                <Body>
                  <FormattedHTMLMessage id="paperpack.text3" />
                </Body>
                <PaperButtons>
                  { locale === 'fr' ?
                    <PaperButton aria-label="Plus d’info" title="Plus d’info" to="/fr/produits/mouchoirs#121a0dec-b670-47f7-801a-970a945fd1e1" modification="pets" convert={false}>
                      Plus d’info
                    </PaperButton>
                    :
                    <PaperButton aria-label="Learn more" title="Learn more" to="/products/facial-tissue#2a5a3a6b-6206-42ab-b752-1da88e52dcad" modification="pets" convert={false}>
                      Learn more
                    </PaperButton>
                  }
                </PaperButtons>
              </TextWrapper>
            </Left>
          </SlideContainer>
        </SwiperSlide>
      </Swiper>
    </MainContainer>
  )
}

HomeHeader.propTypes = {
  // Text that shows at the top
  frontHeaderText: PropTypes.string,
  // Any additional menu elements (used for homepage)
  // Used to change languages
  urls: PropTypes.shape({
    en: PropTypes.string.isRequired,
    fr: PropTypes.string.isRequired,
    zh_hans: PropTypes.string.isRequired,
    zh_hant: PropTypes.string.isRequired,
  }),
  // used to determine current langage
  locale: PropTypes.string
}

HomeHeader.defaultProps = {
  frontHeaderText: '',
  locale: 'en'
}

export default HomeHeader
